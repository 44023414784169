import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { AxiosError } from "axios";
import { ServiceResponse, ErrorResponse } from "../../axios/types";
import { all, takeEvery } from "redux-saga/effects";
import { createWorkerSaga } from "..";
import { NavigateFunction } from "react-router-dom";
import { PreparedDestination } from "@prequel/react";

import MagicLinkService, {
  MagicLinkArgs,
  CustomAxiosError,
  CustomLocalError,
} from "./destination.service";
import { MagicLink } from "../../store/destination";

import { RootState } from "..";

const fetchMagicLink = createAsyncAction(
  "FETCH_MAGICLINK",
  "FETCH_MAGICLINK_SUCCESS",
  "FETCH_MAGICLINK_FAILURE"
)<
  MagicLinkArgs,
  ServiceResponse<{ magic_link: MagicLink }>,
  ErrorResponse<CustomAxiosError>
>();

const createDestination = createAsyncAction(
  "CREATE_DESTINATION",
  "CREATE_DESTINATION_SUCCESS",
  "CREATE_DESTINATION_FAILURE"
)<
  {
    magiclinkargs: MagicLinkArgs;
    destination: PreparedDestination;
    navigate: NavigateFunction;
  },
  ServiceResponse<{}>,
  ErrorResponse<AxiosError>
>();

const testDestination = createAsyncAction(
  "TEST_DESTINATION",
  "TEST_DESTINATION_SUCCESS",
  "TEST_DESTINATION_FAILURE"
)<
  { magiclinkargs: MagicLinkArgs; destination: PreparedDestination },
  ServiceResponse<{}>,
  ErrorResponse<CustomAxiosError>
>();

const sendTestDestinationLocalError = createAction(
  "TEST_DESTINATION_LOCAL_ERROR"
)<{ error: CustomLocalError }>();

const resetTest = createAction("RESET_TEST")();

const fetchDestinationServiceAccount = createAsyncAction(
  "FETCH_SERVICE_ACCOUNT",
  "FETCH_SERVICE_ACCOUNT_SUCCESS",
  "FETCH_SERVICE_ACCOUNT_FAILURE"
)<MagicLinkArgs, ServiceResponse<string>, ErrorResponse<CustomAxiosError>>();

function* watchFetchMagicLink() {
  yield takeEvery(
    fetchMagicLink.request,
    createWorkerSaga(fetchMagicLink, MagicLinkService.getMagicLink)
  );
}

function* watchCreateDestination() {
  yield takeEvery(
    createDestination.request,
    createWorkerSaga(createDestination, MagicLinkService.postDestination)
  );
}

function* watchTestDestination() {
  yield takeEvery(
    testDestination.request,
    createWorkerSaga(testDestination, MagicLinkService.postTestDestination)
  );
}

function* watchFetchDestinationServiceAccount() {
  yield takeEvery(
    fetchDestinationServiceAccount.request,
    createWorkerSaga(
      fetchDestinationServiceAccount,
      MagicLinkService.getDestinationServiceAccount
    )
  );
}

// Selectors
const selectMagicLink = (state: RootState) => state.magiclink.magiclink;
const selectIsLoading = (state: RootState) => state.magiclink.isLoading;
const selectDestinationTest = (state: RootState) =>
  state.magiclink.destinationTest;
const selectDestinationServiceAccount = (state: RootState) =>
  state.magiclink.destinationServiceAccount;

// Reducer Setup
type MagicLinkState = {
  magiclink: MagicLink | undefined;
  isLoading: boolean;
  destinationServiceAccount: string | undefined;
  destinationTest:
    | { status: undefined }
    | { status: "processing" }
    | { status: "success" }
    | { status: "error"; message: string };
};

type MagicLinkActions = ActionType<
  | typeof fetchMagicLink
  | typeof createDestination
  | typeof testDestination
  | typeof resetTest
  | typeof sendTestDestinationLocalError
  | typeof fetchDestinationServiceAccount
>;

const initialState: MagicLinkState = {
  magiclink: undefined,
  isLoading: false,
  destinationTest: { status: undefined },
  destinationServiceAccount: undefined,
};
const reducer = createReducer<MagicLinkState, MagicLinkActions>(initialState)
  .handleAction(fetchMagicLink.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(fetchMagicLink.success, (state, action) => ({
    ...state,
    magiclink: action.payload.data.magic_link,
    isLoading: false,
  }))
  .handleAction(fetchMagicLink.failure, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(testDestination.request, (state) => ({
    ...state,
    destinationTest: { status: "processing" },
  }))
  .handleAction(testDestination.success, (state, action) => ({
    ...state,
    destinationTest: {
      status: "success",
    },
  }))
  .handleAction(testDestination.failure, (state, action) => ({
    ...state,
    destinationTest: {
      status: "error",
      message: action.payload.error.response?.data?.message,
    },
  }))
  .handleAction(resetTest, (state) => ({
    ...state,
    destinationTest: { status: undefined },
  }))
  .handleAction(sendTestDestinationLocalError, (state, action) => ({
    ...state,
    destinationTest: {
      status: "error",
      message: action.payload.error.message,
    },
  }))
  .handleAction(fetchDestinationServiceAccount.success, (state, action) => ({
    ...state,
    destinationServiceAccount: action.payload.data,
  }));

export default reducer;
export function* magiclinkSagas() {
  yield all([
    watchFetchMagicLink(),
    watchCreateDestination(),
    watchTestDestination(),
    watchFetchDestinationServiceAccount(),
  ]);
}
export {
  selectMagicLink,
  selectIsLoading,
  selectDestinationTest,
  fetchMagicLink,
  createDestination,
  testDestination,
  resetTest,
  sendTestDestinationLocalError,
  fetchDestinationServiceAccount,
  selectDestinationServiceAccount,
};
