import { createAction } from "typesafe-actions";
import { RootState } from "..";
import { ToastInterface } from "@prequel-internal/react-components";

enum AlertActionTypes {
  //ADD_ALERT = "ADD_ALERT",
  REMOVE_ALERT = "REMOVE_ALERT",
}

//const addAlert = createAction(AlertActionTypes.ADD_ALERT)<Alert>();
const removeAlert = createAction(AlertActionTypes.REMOVE_ALERT)<{
  toastId: string;
}>();

// Selectors
const selectAlerts = (state: RootState) => state.alerts.alerts;

// Reducer Setup
type AlertsState = {
  alerts: Array<ToastInterface>;
};

const initialState: AlertsState = {
  alerts: [],
};

// TODO: cleanup "any" here
const reducer = (state = initialState, action: any): AlertsState => {
  // clear the error
  if (action.type === AlertActionTypes.REMOVE_ALERT) {
    return {
      ...state,
      alerts: state.alerts.filter(
        (alert) => alert.id !== action.payload.toastId
      ),
    };
  }

  // if the action has no error or is not supposed to do anything about it...
  if (
    !action.payload?.error ||
    action.payload?.error?.suppressGlobalNotification
  ) {
    return state;
  }

  // if the action is a 404, manually suppress the notification
  if (action.payload?.error?.response?.status === 404) {
    return state;
  }

  let alert: ToastInterface | null = null;
  // the only error we get in payload is AxiosError (for now)
  if (action.payload?.error) {
    alert = {
      id: new Date().getTime().toString(),
      kind: "ERROR",
      message:
        action.payload.error.response?.data?.message ??
        action.payload.error.message ??
        "Unknown message",
    };
  } else {
    alert = action.error;
  }
  const newAlerts = alert ? [...state.alerts, alert] : state.alerts;
  return {
    ...state,
    alerts: newAlerts,
  };
};

export default reducer;
export { selectAlerts, removeAlert };
