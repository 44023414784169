import React, { useEffect } from "react";
import { CopyToClipboard } from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchDestinationServiceAccount,
  selectDestinationServiceAccount,
} from "../../../store/destination/destination.duck";
import { useParams } from "react-router-dom";

const ServiceAccount = ({ prodEnv }: { prodEnv: boolean }) => {
  const dispatch = useTypedDispatch();
  const { orgId, linkId } = useParams();
  const serviceAccount = useTypedSelector(selectDestinationServiceAccount);

  useEffect(() => {
    if (orgId && linkId) {
      dispatch(
        fetchDestinationServiceAccount.request({
          orgId: orgId,
          linkId: linkId,
          isProd: prodEnv,
        })
      );
    }
  }, [dispatch, orgId, linkId]);

  if (!serviceAccount) {
    return <></>;
  }

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Service Account
      </label>
      <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono">
        <div className="w-full truncate mr-3">{serviceAccount}</div>
        <CopyToClipboard textToCopy={serviceAccount} />
      </div>
      <p className="mt-2 text-xs text-gray-400">
        {`This is the Service Account we'll use to connect to the Google Sheet.
           Please add it to the list of authorized users in the Google Sheet `}
        <a
          href="https://docs.prequel.co/docs/google-sheets"
          target="_blank"
          rel="noreferrer"
          className="font-medium text-primary-600 hover:text-primary-500"
        >
          (see documentation).
        </a>
      </p>
    </div>
  );
};

export default ServiceAccount;
