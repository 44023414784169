import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  useDestinationVendors,
  VendorField,
  PreparedDestination,
  Destination,
  prepareDestination,
} from "@prequel/react";
import {
  Checkbox,
  FormField,
  TextArea,
} from "@prequel-internal/react-components";

import BrandedLogo from "../../components/BrandedLogo";
import { ReactComponent as SpinnerIcon } from "../../assets/icons/spinner.svg";
import { ReactComponent as AlertCircleIcon } from "../../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../assets/icons/alert-triangle.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as EllipsisIcon } from "../../assets/icons/ellipsis.svg";

import { useState } from "react";
import { useTypedDispatch, useTypedSelector } from "../../store"; // Redux - global state type import
import {
  createDestination,
  testDestination,
  resetTest,
  selectDestinationTest,
} from "../../store/destination/destination.duck";
import {
  getVendorLogo,
  getValidServiceAccountKey,
  MagicLink,
} from "../../store/destination";

import SSHTunnel from "./SSHTunnel";
import ServiceAccount from "./ServiceAccount";
import BaseForm from "./BaseForm";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

type ConnectProps = {
  orgId: string;
  linkId: string;
  magicLink: MagicLink;
  prodEnv: boolean;
};
const Connect = ({ magicLink, orgId, linkId, prodEnv }: ConnectProps) => {
  const destinationTest = useTypedSelector(selectDestinationTest);
  const destinationVendors = useDestinationVendors(
    process.env.REACT_APP_API_SERVER
  );

  const [fields, setFields] = useState<Destination>({
    name: magicLink.destination_name,
    schema: "",
    host: magicLink.host,
    port: "",
    database: "",
    username: "",
    password: "",
    service_account_key: "",
    vendor: magicLink.vendor,
    bucket_name: magicLink.bucket_name,
    id_in_provider_system: "", // should remain blank, set in the backend
    bucket_region: "",
    bucket_access_id: magicLink.bucket_access_id,
    bucket_secret_key: "",
    bucket_vendor: magicLink.bucket_vendor,
    enabled_models: [],
    use_ssh_tunnel: false,
    ssh_tunnel_host: "",
    ssh_tunnel_port: "",
    ssh_tunnel_username: "",
    ssh_public_key: "",
  });
  const [enabledModels, setEnabledModels] = useState<string[]>(
    magicLink.available_models
  );
  const [modelsError, setModelsError] = useState<string>();
  const [allFutureModels, setAllFutureModels] = useState<boolean>(true);

  const destinationVendor = useMemo(
    () =>
      destinationVendors.destinations?.find(
        (dV) => dV.vendor_name === magicLink.vendor
      ),
    [destinationVendors]
  );
  const VendorLogo = useMemo(
    () => getVendorLogo(destinationVendor?.vendor_name || ""),
    [destinationVendor]
  );

  // convert into map of name -> object
  const formFields: { [key: string]: VendorField } = useMemo(() => {
    if (!destinationVendor) {
      return {};
    }

    return destinationVendor.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [destinationVendor]);

  const preparedDestination: PreparedDestination = useMemo(
    () => prepareDestination(fields),
    [fields]
  );

  // On service_account_key changes, attempt to coerce the string into the JSON object
  const tokenIsValid = useMemo(
    () => !!getValidServiceAccountKey(fields.service_account_key),
    [fields.service_account_key]
  );

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  // Reset test on form change
  useEffect(() => {
    dispatch(resetTest());
  }, [dispatch, fields]);

  useEffect(() => {
    setModelsError(undefined);
  }, [enabledModels]);

  const updateEnabledModels = (isEnabled: boolean, modelName: string) => {
    let updatedModels: string[] = [];
    if (isEnabled) {
      updatedModels = [...enabledModels, modelName];
    } else {
      updatedModels = enabledModels.filter((m) => m !== modelName);
    }
    setEnabledModels(updatedModels);
  };

  // Handle destination submission
  const onConnectHandler = () => {
    if (enabledModels.length === 0) {
      setModelsError("This is a required field");
      return;
    }

    preparedDestination.enabled_models = enabledModels;
    if (allFutureModels) {
      preparedDestination.enabled_models = ["*"];
    }

    dispatch(
      createDestination.request({
        magiclinkargs: { orgId: orgId, linkId: linkId, isProd: prodEnv },
        destination: preparedDestination,
        navigate,
      })
    );
  };

  // Handle destination test
  const onTestHandler = (destination: Destination) => {
    dispatch(
      testDestination.request({
        magiclinkargs: { orgId: orgId, linkId: linkId, isProd: prodEnv },
        destination: preparedDestination,
      })
    );
  };

  const setField = (key: string, value: string) => {
    setFields((prevFields) => ({
      ...prevFields,
      [key]: value,
    }));
  };

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onTestHandler(fields);
  };
  return (
    <>
      <nav
        className="bg-white-100 bg-white border-b border-gray-200"
        aria-label="Global"
      >
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center px-2 lg:px-0">
              <div className="flex-shrink-0 inline-flex items-center justify-center w-36 rounded-lg">
                <BrandedLogo orgId={orgId} />
              </div>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
        <form onSubmit={onSubmitHandler}>
          <div className="space-y-6 divide-y divide-gray-200">
            <div className="space-y-6">
              <div>
                <h1 className="text-lg leading-6 font-medium text-gray-900">
                  Connect destination
                </h1>
              </div>

              <div>
                <label
                  htmlFor="destination-type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Destination Type
                </label>
                <div className="flex pt-2">
                  <VendorLogo className="flex-shrink-0 h-6 w-6 rounded-full mr-3" />
                  {destinationVendor?.display_name}
                </div>
              </div>
            </div>

            <div>
              <div className="space-y-6 pt-8">
                {destinationVendor && (
                  <BaseForm destinationVendor={destinationVendor} />
                )}
                {"schema" in formFields && (
                  <FormField
                    id="schema"
                    type="text"
                    label="Name the destination schema"
                    placeholder={formFields.schema.placeholder}
                    subtext={formFields.schema.help}
                    value={fields?.schema}
                    onChangeHandler={(value: string) => {
                      setField("schema", value);
                    }}
                    required={formFields.schema.is_required}
                    disabled={destinationTest?.status === "processing"}
                  />
                )}
                {destinationVendor?.uses_service_account ? (
                  <>
                    {"host" in formFields && (
                      <FormField
                        id="host"
                        type="text"
                        label={formFields.host.label}
                        placeholder={formFields.host.placeholder}
                        subtext={formFields.host.help}
                        value={fields?.host}
                        onChangeHandler={(value: string) => {
                          setField("host", value);
                        }}
                        required={formFields.host.is_required}
                        disabled
                      />
                    )}
                    <ServiceAccount prodEnv={prodEnv} />
                  </>
                ) : (
                  <div className="space-y-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Enter the user credentials
                    </label>
                    <div className="mt-1">
                      <p className="mt-1 text-sm text-gray-500">
                        {`Copy and paste these credentials from ${destinationVendor?.display_name} into the
                        form below. If you need help, `}
                        <a
                          href={destinationVendor?.docs}
                          target="_blank"
                          rel="noreferrer"
                          className="font-medium text-primary-600 hover:text-primary-500"
                        >
                          {`view our documentation on ${destinationVendor?.display_name}.`}
                        </a>
                      </p>
                    </div>
                    <div className="space-y-4 pt-6">
                      {"host" in formFields && (
                        <FormField
                          id="host"
                          type="text"
                          label={formFields.host.label}
                          placeholder={formFields.host.placeholder}
                          subtext={formFields.host.help}
                          value={fields?.host}
                          onChangeHandler={(value: string) => {
                            setField("host", value);
                          }}
                          required={formFields.host.is_required}
                          disabled
                        />
                      )}
                      {"port" in formFields && (
                        <FormField
                          id="port"
                          type="text"
                          label={formFields.port.label}
                          placeholder={formFields.port.placeholder}
                          subtext={formFields.port.help}
                          value={fields?.port}
                          onChangeHandler={(value: string) => {
                            setField("port", value);
                          }}
                          required={formFields.port.is_required}
                          disabled={destinationTest?.status === "processing"}
                        />
                      )}
                      {"database" in formFields && (
                        <FormField
                          id="database"
                          type="text"
                          label={formFields.database.label}
                          placeholder={formFields.database.placeholder}
                          subtext={formFields.database.help}
                          value={fields?.database}
                          onChangeHandler={(value: string) => {
                            setField("database", value);
                          }}
                          required={formFields.database.is_required}
                          disabled={destinationTest?.status === "processing"}
                        />
                      )}
                      {"username" in formFields && (
                        <FormField
                          id="username"
                          type="text"
                          label={formFields.username.label}
                          placeholder={formFields.username.placeholder}
                          subtext={formFields.username.help}
                          value={fields?.username}
                          onChangeHandler={(value: string) => {
                            setField("username", value);
                          }}
                          required={formFields.username.is_required}
                          disabled={destinationTest?.status === "processing"}
                        />
                      )}
                      {"password" in formFields && (
                        <FormField
                          id="password"
                          type="password"
                          label={formFields.password.label}
                          placeholder={formFields.password.placeholder}
                          subtext={formFields.password.help}
                          value={fields?.password}
                          onChangeHandler={(value: string) => {
                            setField("password", value);
                          }}
                          required={formFields.password.is_required}
                          disabled={destinationTest?.status === "processing"}
                        />
                      )}
                      {"service_account_key" in formFields && (
                        <TextArea
                          id="service_account_key"
                          subtext={formFields.service_account_key.help}
                          value={fields?.service_account_key}
                          onChangeHandler={(value: string) => {
                            setField("service_account_key", value);
                          }}
                          invalid={!tokenIsValid}
                          required={formFields.service_account_key.is_required}
                          disabled={destinationTest?.status === "processing"}
                        />
                      )}
                      {"bucket_vendor" in formFields && (
                        <FormField
                          id="bucket_vendor"
                          type="text"
                          label={formFields.bucket_vendor.label}
                          placeholder={formFields.bucket_vendor.placeholder}
                          subtext={formFields.bucket_vendor.help}
                          value={fields?.bucket_vendor}
                          onChangeHandler={(value: string) => {
                            setField("bucket_vendor", value);
                          }}
                          required={formFields.bucket_vendor.is_required}
                          disabled
                        />
                      )}
                      {"bucket_name" in formFields && (
                        <FormField
                          id="bucket_name"
                          type="text"
                          label={formFields.bucket_name.label}
                          placeholder={formFields.bucket_name.placeholder}
                          subtext={formFields.bucket_name.help}
                          value={fields?.bucket_name}
                          onChangeHandler={(value: string) => {
                            setField("bucket_name", value);
                          }}
                          required={formFields.bucket_name.is_required}
                          disabled
                        />
                      )}
                      {"bucket_region" in formFields && (
                        <FormField
                          id="bucket_region"
                          type="text"
                          label={formFields.bucket_region.label}
                          placeholder={formFields.bucket_region.placeholder}
                          subtext={formFields.bucket_region.help}
                          value={fields?.bucket_region}
                          onChangeHandler={(value: string) => {
                            setField("bucket_region", value);
                          }}
                          required={formFields.bucket_region.is_required}
                          disabled={destinationTest?.status === "processing"}
                        />
                      )}
                      {"bucket_access_id" in formFields && (
                        <FormField
                          id="bucket_access_id"
                          type="text"
                          label={formFields.bucket_access_id.label}
                          placeholder={formFields.bucket_access_id.placeholder}
                          subtext={formFields.bucket_access_id.help}
                          value={fields?.bucket_access_id}
                          onChangeHandler={(value: string) => {
                            setField("bucket_access_id", value);
                          }}
                          required={formFields.bucket_access_id.is_required}
                          disabled={
                            magicLink.vendor === "abs" ||
                            magicLink.bucket_vendor === "abs"
                          }
                        />
                      )}
                      {"bucket_secret_key" in formFields && (
                        <FormField
                          id="bucket_secret_key"
                          type="password"
                          label={formFields.bucket_secret_key.label}
                          placeholder={formFields.bucket_secret_key.placeholder}
                          subtext={formFields.bucket_secret_key.help}
                          value={fields?.bucket_secret_key}
                          onChangeHandler={(value: string) => {
                            setField("bucket_secret_key", value);
                          }}
                          required={formFields.bucket_secret_key.is_required}
                          disabled={destinationTest?.status === "processing"}
                        />
                      )}
                    </div>
                  </div>
                )}
                {destinationVendor?.supports_ssh_tunnel && (
                  <SSHTunnel
                    fields={fields}
                    setField={setField}
                    publicKey={magicLink.ssh_public_key}
                  />
                )}
                {/* Special case for default value of "*", don't show model selectors */}
                {(magicLink.available_models.length > 1 ||
                  (magicLink.available_models.length == 1 &&
                    magicLink.available_models[0] !== "*")) && (
                  <>
                    <div className="h-px w-full bg-gray-200"></div>
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Select what models to receive
                      </label>
                      <div className="space-y-2">
                        {magicLink.available_models.map((m) => (
                          <Checkbox
                            key={m}
                            id={m}
                            label={m}
                            checked={
                              enabledModels.includes(m) || allFutureModels
                            }
                            setChecked={(isChecked: boolean) =>
                              updateEnabledModels(isChecked, m)
                            }
                            disabled={allFutureModels}
                          />
                        ))}
                        <Checkbox
                          key={"all_models_present_future"}
                          id={"all_models_present_future"}
                          label={"All current and future models"}
                          description={
                            "Any new tables added in the future will be synced automatically"
                          }
                          checked={allFutureModels}
                          setChecked={(isChecked: boolean) =>
                            setAllFutureModels(isChecked)
                          }
                        />
                      </div>
                      {modelsError && (
                        <p className="mt-1 text-xs font-medium text-red-600">
                          {modelsError}
                        </p>
                      )}
                    </div>
                  </>
                )}
                {/* Testing box */}
                <div className="relative pt-4">
                  <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
                    <div className="p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div
                            className={classNames(
                              "mx-auto flex items-center justify-center h-10 w-10 rounded-full",
                              destinationTest.status === "success"
                                ? "bg-emerald-100"
                                : "",
                              destinationTest.status === "error"
                                ? "bg-red-100"
                                : "",
                              destinationTest.status === "processing"
                                ? "bg-gray-100"
                                : "",
                              !destinationTest.status ? "bg-gray-100" : ""
                            )}
                          >
                            {destinationTest.status === "success" && (
                              <CheckIcon
                                className="h-5 w-5 text-emerald-600"
                                aria-hidden="true"
                              />
                            )}
                            {destinationTest.status === "error" && (
                              <AlertCircleIcon
                                className="h-5 w-5 text-red-600"
                                aria-hidden="true"
                              />
                            )}
                            {destinationTest.status === "processing" && (
                              <EllipsisIcon
                                className="h-5 w-5 text-gray-600"
                                aria-hidden="true"
                              />
                            )}
                            {!destinationTest.status && (
                              <AlertTriangleIcon
                                className="h-5 w-5 text-gray-600"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                        <div className="ml-3 mt-1">
                          <h3 className="text-lg font-medium text-gray-800">
                            {destinationTest.status === "success" &&
                              "Connection successful!"}
                            {destinationTest.status === "error" &&
                              "Error connecting to destination."}
                            {destinationTest.status === "processing" &&
                              "Testing connection..."}
                            {!destinationTest.status &&
                              "Test connection before saving."}
                          </h3>
                          <div className="mt-2 mb-2 text-sm text-gray-700">
                            <p>
                              {destinationTest.status === "success" &&
                                "Connection was successfully established to this destination. The destination details can now be saved."}
                              {destinationTest.status === "error" && (
                                <>
                                  <span className="block mb-4">
                                    Try updating the destination details and try
                                    again.
                                  </span>
                                  <code className="text-xs">
                                    {destinationTest.message}
                                  </code>
                                </>
                              )}
                              {destinationTest.status === "processing" && ""}
                              {!destinationTest.status && ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Spacer element to match the height of the toolbar */}
                    <div aria-hidden="true">
                      <div className="h-px" />
                      <div className="py-2">
                        <div className="py-px">
                          <div className="h-9" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute bottom-0 inset-x-px">
                    <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <button
                            type="submit"
                            disabled={destinationTest.status === "processing"}
                            name="test"
                            id="test"
                            //onClick={() => onTestHandler(d)}
                            className={classNames(
                              "inline-flex py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:cursor-not-allowed",
                              destinationTest.status === "success"
                                ? " text-gray-700 border border-gray-300 bg-white hover:bg-gray-50"
                                : "",
                              destinationTest.status === "error"
                                ? "text-white bg-emerald-500 hover:bg-emerald-600"
                                : "",
                              destinationTest.status === "processing"
                                ? " text-gray-700 border border-gray-300 bg-white hover:bg-gray-50"
                                : "",
                              !destinationTest.status
                                ? "text-white bg-emerald-500 hover:bg-emerald-600"
                                : ""
                            )}
                          >
                            {destinationTest.status === "processing" ? (
                              <>
                                <SpinnerIcon
                                  className="animate-spin h-5 w-5 mr-3 text-gray-500"
                                  viewBox="0 0 24 24"
                                />{" "}
                                Testing Connection...
                              </>
                            ) : (
                              "Test Connection"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-2">
                  <button
                    type="button"
                    disabled={!(destinationTest.status === "success")}
                    onClick={onConnectHandler}
                    name="save"
                    id="save"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-500"
                  >
                    Save Destination
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default Connect;
