import React, { useState } from "react";
import { Destination } from "@prequel/react";
import { FormField, Toggle } from "@prequel-internal/react-components";

import SSHPublicKey from "../SSHPublicKey";

type SSHTunnelProps = {
  fields: Omit<Destination, "vendor">;
  setField: (key: string, value: string) => void;
  publicKey: string;
};
const SSHTunnel = ({ fields, setField, publicKey }: SSHTunnelProps) => {
  const [useSSHTunnel, setUseSSHTunnel] = useState(fields.use_ssh_tunnel);

  return (
    <>
      <div className="h-px w-full bg-gray-200" />
      <div className="space-y-4">
        <Toggle
          label="Connect using SSH tunnel"
          enabled={useSSHTunnel}
          setEnabled={(isChecked: boolean) => {
            setUseSSHTunnel(isChecked);
            isChecked
              ? setField("ssh_public_key", publicKey)
              : setField("ssh_public_key", "");
          }}
        />
        {useSSHTunnel && (
          <>
            <SSHPublicKey publicKey={publicKey} />
            <FormField
              label="SSH Tunnel Host"
              id="ssh_tunnel_host"
              type="text"
              value={fields.ssh_tunnel_host}
              onChangeHandler={(value: string) => {
                setField("ssh_tunnel_host", value);
              }}
              required={useSSHTunnel}
            />

            <FormField
              label="SSH Tunnel Port"
              id="ssh_tunnel_port"
              type="number"
              placeholder={useSSHTunnel ? "22" : ""}
              value={fields.ssh_tunnel_port}
              onChangeHandler={(value: string) => {
                setField("ssh_tunnel_port", value);
              }}
              required={useSSHTunnel}
            />

            <FormField
              label="SSH Tunnel Username"
              id="ssh_tunnel_username"
              type="text"
              value={fields.ssh_tunnel_username}
              onChangeHandler={(value: string) => {
                setField("ssh_tunnel_username", value);
              }}
              required={useSSHTunnel}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SSHTunnel;
