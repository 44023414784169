import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux"; // Redux
import { all, call, put } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import { PayloadActionCreator } from "typesafe-actions";
import { AxiosError } from "axios";
import { ErrorResponse } from "../axios/types";

import magiclinkReducer, {
  magiclinkSagas,
} from "./destination/destination.duck";
import alertsReducer from "./alerts/alerts.duck";

export const createRootReducer = () =>
  combineReducers({
    magiclink: magiclinkReducer,
    alerts: alertsReducer,
  });

// enable redux devtools (see https://extension.remotedev.io/docs/Recipes.html#using-in-a-typescript-project)
// using create react app, the NODE_ENV variable is set to 'development' in development
// using that, only use redux devtools in development
const composeEnhancers: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

type AsyncAction<R, S> = {
  request: PayloadActionCreator<string, R>;
  success: PayloadActionCreator<string, S>;
  failure: PayloadActionCreator<string, ErrorResponse<AxiosError>>;
};
type ApiCallType<R, S> = (payload: R) => Promise<S>;

export const createWorkerSaga = <R, S>(
  action: AsyncAction<R, S>,
  apiCall: ApiCallType<R, S>
) => {
  return function* saga({ payload }: { payload: R }) {
    try {
      const result: S = yield call(apiCall, payload);
      yield put(action.success(result));
    } catch (error: any) {
      const e: AxiosError = error;
      yield put(action.failure({ error: e }));
    }
  };
};

function* rootSaga() {
  yield all([magiclinkSagas()]);
}

// store is the redux state store
const rootReducer = createRootReducer();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
export { rootReducer };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
