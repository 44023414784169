import axios from "axios";

//dev-api
// creates a copy of the axios instance for changing defaults
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

instance.defaults.headers.common["Content-Type"] = "application/json";

enum ApiRoutes {
  LINK = "/link",
  EXPIRED = "/expired",
  SUCCESS = "/success",
}

export default instance;
export { ApiRoutes };
