import React, { useState } from "react";

type BrandedLogoProps = {
  orgId?: string;
};

const BrandedLogo = ({ orgId }: BrandedLogoProps) => {
  // eslint-disable-next-line
  const fallbackImageSrc = require("../../assets/logos/prequel.svg").default;
  // Set the initial state of the image
  const [imageSrc, setImageSrc] = useState<string>(
    orgId
      ? `https://storage.googleapis.com/${process.env.REACT_APP_ASSET_BUCKET}/${orgId}.svg`
      : fallbackImageSrc
  );

  // If there's an issue loading the image, defer to a fallback
  const onImageLoadError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setImageSrc(fallbackImageSrc);
  };

  return (
    <img
      className="h-10 w-auto "
      src={imageSrc}
      alt=""
      onError={onImageLoadError}
    />
  );
};

export default BrandedLogo;
