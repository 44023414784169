import { ReactComponent as AthenaLogo } from "../../assets/logos/athena.svg";
import { ReactComponent as AuroraMySQLLogo } from "../../assets/logos/aurora_mysql.svg";
import { ReactComponent as AuroraPostgresLogo } from "../../assets/logos/aurora_postgres.svg";
import { ReactComponent as AzureBlobStorageLogo } from "../../assets/logos/abs.svg";
import { ReactComponent as BigQueryLogo } from "../../assets/logos/bigquery.svg";
import { ReactComponent as ClickHouseLogo } from "../../assets/logos/clickhouse.svg";
import { ReactComponent as DatabricksLogo } from "../../assets/logos/databricks.svg";
import { ReactComponent as GCSLogo } from "../../assets/logos/gcs.svg";
import { ReactComponent as GenericLogo } from "../../assets/logos/generic.svg";
import { ReactComponent as GoogleSheetsLogo } from "../../assets/logos/google_sheets.svg";
import { ReactComponent as MySqlLogo } from "../../assets/logos/mysql.svg";
import { ReactComponent as PostgresLogo } from "../../assets/logos/postgresql.svg";
import { ReactComponent as RedshiftLogo } from "../../assets/logos/redshift.svg";
import { ReactComponent as SingleStoreLogo } from "../../assets/logos/singlestore.svg";
import { ReactComponent as SnowflakeLogo } from "../../assets/logos/snowflake.svg";
import { ReactComponent as SQLServerLogo } from "../../assets/logos/sql_server.svg";
import { ReactComponent as S3Logo } from "../../assets/logos/s3.svg";

export interface MagicLink {
  destination_name: string;
  host: string;
  bucket_name: string;
  vendor: string;
  created_at: string;
  has_been_used: boolean;
  available_models: string[];
  bucket_vendor: string;
  bucket_access_id: string;
  ssh_public_key: string;
}

export type ServiceAccountKey = {
  type: string;
  project_id: string;
  private_key_id: string;
  client_email: string;
  client_id: string;
  auth_url: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
};

const vendorLogos: { [key: string]: React.FunctionComponent } = {
  abs: AzureBlobStorageLogo,
  athena: AthenaLogo,
  aurora_mysql: AuroraMySQLLogo,
  aurora_postgres: AuroraPostgresLogo,
  bigquery: BigQueryLogo,
  clickhouse: ClickHouseLogo,
  databricks: DatabricksLogo,
  gcs: GCSLogo,
  google_sheets: GoogleSheetsLogo,
  mysql: MySqlLogo,
  postgres: PostgresLogo,
  redshift: RedshiftLogo,
  singlestore: SingleStoreLogo,
  snowflake: SnowflakeLogo,
  sql_server: SQLServerLogo,
  s3: S3Logo,
  s3_compatible: S3Logo,
};

export const getVendorLogo = (
  vendor: string
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> =>
  vendorLogos[vendor] ?? GenericLogo;

export const getValidServiceAccountKey = (
  string?: string
): ServiceAccountKey | undefined => {
  if (!string) {
    return undefined;
  }
  try {
    const token: ServiceAccountKey = JSON.parse(string);

    return token;
  } catch (e) {
    return undefined;
  }
};
