import axios from "../../axios/api";
import { PreparedDestination } from "@prequel/react";
import { ServiceResponse } from "../../axios/types";
import { AxiosError } from "axios";
import { MagicLink } from "../../store/destination";
import { NavigateFunction } from "react-router-dom";

export interface MagicLinkArgs {
  orgId: string;
  linkId: string;
  isProd: boolean;
}

export interface CustomAxiosError extends AxiosError<any, any> {
  suppressGlobalNotification?: boolean;
}

export interface CustomLocalError extends Error {
  suppressGlobalNotification?: boolean;
}

const getMagicLink: (
  magiclinkargs: MagicLinkArgs
) => Promise<ServiceResponse<{ magic_link: MagicLink }>> = (magiclinkargs) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .get(
      `public/orgs/${magiclinkargs.orgId}/${environment}/magic-links/${magiclinkargs.linkId}`
    )
    .then(({ data }: { data: { data: { magic_link: MagicLink } } }) => {
      return data;
    })
    .catch((reason: AxiosError) => {
      // Suppress the error alert for an expires/incorrect link -- that will be handled by a specific component instead
      const e: CustomAxiosError = {
        ...reason,
        suppressGlobalNotification: true,
      };
      throw e;
    });
};

//export interface DestinationPostRequest extends AnyDestination {}

const postDestination: ({
  magiclinkargs,
  destination,
  navigate,
}: {
  magiclinkargs: MagicLinkArgs;
  destination: PreparedDestination;
  navigate: NavigateFunction;
}) => Promise<ServiceResponse<{}>> = ({
  magiclinkargs,
  destination,
  navigate,
}) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/magic-links/${magiclinkargs.linkId}/convert`,
      {
        destination: destination,
      }
    )
    .then(() => {
      navigate("success", { replace: false });
      return { data: {} };
    })
    .catch((reason: AxiosError) => {
      throw reason;
    });
};

const postTestDestination: ({
  magiclinkargs,
  destination,
}: {
  magiclinkargs: MagicLinkArgs;
  destination: PreparedDestination;
}) => Promise<ServiceResponse<{}>> = ({ magiclinkargs, destination }) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/magic-links/${magiclinkargs.linkId}/test-connection`,
      {
        destination: destination,
      }
    )
    .then((data) => {
      return { data: {} }; // Append the destination details to associate the test with a set of destination details
    })
    .catch((reason: AxiosError) => {
      const e: CustomAxiosError = {
        ...reason,
        suppressGlobalNotification: true,
      };
      throw e;
    });
};

const getDestinationServiceAccount: (
  magicLinkArgs: MagicLinkArgs
) => Promise<ServiceResponse<string>> = (magicLinkArgs) => {
  const environment = magicLinkArgs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magicLinkArgs.orgId}/${environment}/magic-links/${magicLinkArgs.linkId}/generate-service-account`
    )
    .then(({ data }: { data: { data: { service_account_name: string } } }) => ({
      data: data.data.service_account_name,
    }))
    .catch((reason: AxiosError) => {
      const e: CustomAxiosError = {
        ...reason,
        suppressGlobalNotification: true,
      };
      throw e;
    });
};

const destination = {
  getMagicLink,
  postDestination,
  postTestDestination,
  getDestinationServiceAccount,
};
export default destination;
