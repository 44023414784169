import React from "react";
import { useParams } from "react-router-dom";
import BrandedLogo from "../../components/BrandedLogo";

import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

const Success = () => {
  const { orgId } = useParams();

  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <span className="sr-only">Success!</span>
            <div className="flex-shrink-0 inline-flex items-center justify-center w-48 rounded-lg">
              <BrandedLogo orgId={orgId} />
            </div>
          </div>
          <div className="py-20">
            <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-emerald-100">
              <CheckIcon
                className="h-12 w-12 text-emerald-600"
                aria-hidden="true"
              />
            </div>
            <div className="text-center">
              <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Success!
              </h1>
              <p className="mt-2 text-base text-gray-500">
                You have successfully submitted your destination.
              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Success;
